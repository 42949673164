import React from 'react';
import './LookFor.css';
import ArrowRightSmall from '../../svg/ArrowRightSmall';

const LookForSection = () => {
  return (
    <section className="look-for">
      <div className="max-width">
        <h1>What We Look For</h1>
        <div className="look-for-content">
          <div className="description">
            <p>
            Every position is different and will require a different set of skills. However, people who thrive here share a number of key attributes:
            </p>
          </div>
          <div className="item-list">
            <ul className="dashed">
              <li>Passion</li>
              <li>Innovation</li>
              <li>An entrepreneurial spirit</li>
              <li>A commitment to delivering the best for clients.</li>
            </ul>
          </div>
          <div className="description">
            <p>
            Check back here to see current vacancies, or feel free to send us your CV.
            </p>
          </div>
          <div className="link">
            <a href="/contact">apply now</a>
            <i>
              {' '}
              <ArrowRightSmall />
            </i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LookForSection;
