import React, { useEffect } from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const onClickScrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    onClickScrollToTop();
  }, []);

  return (
    <footer>
      <ul>
        <li>
          © 2020{' '}
          <NavLink to="/" onClick={() => onClickScrollToTop()}>
            - Systematic Alpha Oy
          </NavLink>{' '}
          - All Rights Reserved
        </li>
        <li>
          <NavLink to="/policy" onClick={() => onClickScrollToTop()}>
            Policy
          </NavLink>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
