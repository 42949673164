import React from 'react';

const UserIcon = (props) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0.984131C10.7598 0.984131 11.968 1.48446 12.8588 2.37506C13.7496 3.26566 14.25 4.47358 14.25 5.73307C14.25 6.99257 13.7496 8.20048 12.8588 9.09108C11.968 9.98168 10.7598 10.482 9.5 10.482C8.24022 10.482 7.03204 9.98168 6.14124 9.09108C5.25044 8.20048 4.75 6.99257 4.75 5.73307C4.75 4.47358 5.25044 3.26566 6.14124 2.37506C7.03204 1.48446 8.24022 0.984131 9.5 0.984131ZM9.5 12.8565C14.7487 12.8565 19 14.9816 19 17.6054V19.9799H0V17.6054C0 14.9816 4.25125 12.8565 9.5 12.8565Z"
        fill="#46D0D6"
      />
    </svg>
  );
};

export default UserIcon;
