import React from 'react';

const TimeLineIco = (props) => {
  return (
    <svg
      width="46"
      height="26"
      viewBox="0 0 46 26"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1429 0.142883C41.1607 0.142883 40.3199 0.492586 39.6205 1.19199C38.9211 1.8914 38.5714 2.73217 38.5714 3.71431C38.5714 4.07146 38.6458 4.45836 38.7946 4.87503L30.0893 12.375C29.4048 11.8393 28.6607 11.5715 27.8571 11.5715C26.9048 11.5715 26.0714 11.9137 25.3571 12.5982L18.5268 9.16074C18.4673 8.23812 18.1027 7.45687 17.433 6.81699C16.7634 6.17711 15.9524 5.85717 15 5.85717C14.3452 5.85717 13.7426 6.01342 13.192 6.32592C12.6414 6.63842 12.2098 7.06997 11.8973 7.62056C11.5848 8.17116 11.4286 8.77384 11.4286 9.4286C11.4286 9.93455 11.5476 10.4405 11.7857 10.9465L4.82143 18.9375C4.40476 18.7887 3.9881 18.7143 3.57143 18.7143C2.58929 18.7143 1.74851 19.064 1.04911 19.7634C0.349702 20.4628 0 21.3036 0 22.2857C0 23.2679 0.349702 24.1087 1.04911 24.8081C1.74851 25.5075 2.58929 25.8572 3.57143 25.8572C4.55357 25.8572 5.39435 25.5075 6.09375 24.8081C6.79315 24.1087 7.14286 23.2679 7.14286 22.2857C7.14286 21.8393 7.05357 21.3929 6.875 20.9465L13.9286 12.8215C14.3155 12.9405 14.6726 13 15 13C15.506 13 15.9821 12.8959 16.4286 12.6875C16.875 12.4792 17.2768 12.1965 17.6339 11.8393L24.2857 15.1875C24.2857 15.6637 24.3824 16.1176 24.5759 16.5491C24.7693 16.9807 25.0223 17.3527 25.3348 17.6652C25.6473 17.9777 26.0268 18.2307 26.4732 18.4241C26.9196 18.6176 27.381 18.7143 27.8571 18.7143C28.125 18.7143 28.3929 18.6846 28.6607 18.625C28.9286 18.5655 29.1815 18.4762 29.4196 18.3572C29.6577 18.2381 29.881 18.0968 30.0893 17.9331C30.2976 17.7694 30.4836 17.5834 30.6473 17.375C30.811 17.1667 30.9524 16.9435 31.0714 16.7054C31.1905 16.4673 31.2798 16.2143 31.3393 15.9465C31.3988 15.6786 31.4286 15.4107 31.4286 15.1429V14.9643L40.7143 6.97324C41.1607 7.18157 41.6369 7.28574 42.1429 7.28574C43.125 7.28574 43.9658 6.93604 44.6652 6.23663C45.3646 5.53723 45.7143 4.69646 45.7143 3.71431C45.7143 2.73217 45.3646 1.8914 44.6652 1.19199C43.9658 0.492586 43.125 0.142883 42.1429 0.142883Z"
        fill="#46D0D6"
      />
    </svg>
  );
};

export default TimeLineIco;
