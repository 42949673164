import React from 'react';

const Card = ({ cardNumber, title, text, children }) => {
  return (
    <div className={`card ${cardNumber}`}>
      <div className="header">
        <h4>{title}</h4>
        <i>{children}</i>
      </div>

      <p>{text}</p>
    </div>
  );
};

export default Card;
