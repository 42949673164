import React, { useEffect } from 'react';
import Header from '../common/Header.jsx';
import BoxText from '../common/BoxText';
import './Careers.css';
import MiddleSection from './MiddleSection.jsx';

import PositionSection from './PositionSection.jsx';
import LookForSection from './LookForSection.jsx';

const Careers = () => {
  /** Forces the page to scroll to the top every time it re renders */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header className={'careers'} text={'Careers'} />
      <BoxText
        className={'working-with-us'}
        title={'Working With Us'}
        description={
          'At Systematic Alpha we believe success stems from the strength of our team. Technology may underpin much of our strategy, but it’s people who put that technology to work. We are always on the look out for people with a passion for innovation, financial expertise and technical knowledge. Our team come from a range of backgrounds. Some come from finance, others from business or technology. What they all have in common is an entrepreneurial spirit and a dedication to excellence in everything they do.'
        }
      />
      <MiddleSection />
      <PositionSection />
      <LookForSection />
    </React.Fragment>
  );
};

export default Careers;
