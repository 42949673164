import React from 'react';

export const RetangleChartIcon = (props) => {
  return (
    <svg
      width="72"
      height="50"
      viewBox="0 0 72 50"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.6312 40.9609H6.59617C6.2809 40.9609 6.04443 40.7245 6.04443 40.4092V1.55171C6.04443 1.39408 6.12326 1.23644 6.20208 1.15762C6.2809 1.0788 6.43854 1 6.59617 1C13.6898 1 25.0397 1 36.3107 1C47.4241 1 58.5375 1 65.6312 1C65.9464 1 66.1829 1.23644 66.1829 1.55171V40.4092C66.1829 40.7245 65.9464 40.9609 65.6312 40.9609ZM7.14791 39.7787H65.0795V2.10345C57.907 2.10345 47.1089 2.10345 36.3107 2.10345C25.2762 2.10345 14.3204 2.10345 7.14791 2.10345V39.7787Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M66.8922 49.631H5.25622C2.89166 49.631 1 47.7393 1 45.3748V41.4339C1 40.5668 1.70937 39.8575 2.57637 39.8575H69.4144C70.2814 39.8575 70.9908 40.5668 70.9908 41.4339V45.4536C71.1485 47.7393 69.2568 49.631 66.8922 49.631ZM2.65522 40.9609C2.41876 40.9609 2.18228 41.1974 2.18228 41.4339V45.4536C2.18228 47.1876 3.60103 48.5275 5.25622 48.5275H66.8922C68.6263 48.5275 69.9661 47.1088 69.9661 45.4536V41.4339C69.9661 41.1974 69.7297 40.9609 69.4932 40.9609H2.65522Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M36.0742 46.7935C34.8919 46.7935 34.0249 45.8477 34.0249 44.6654C34.0249 43.4831 34.9707 42.5373 36.0742 42.5373C37.2564 42.5373 38.1235 43.4831 38.1235 44.6654C38.2023 45.8477 37.2564 46.7935 36.0742 46.7935ZM36.0742 43.7984C35.5224 43.7984 35.1284 44.1925 35.1284 44.7442C35.1284 45.296 35.5224 45.69 36.0742 45.69C36.6259 45.69 37.02 45.296 37.02 44.7442C37.02 44.1925 36.6259 43.7984 36.0742 43.7984Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M26.2221 31.1087C25.9068 31.1087 25.6704 30.8722 25.6704 30.5569V9.74886C25.6704 9.43358 25.9068 9.19714 26.2221 9.19714C26.5374 9.19714 26.7738 9.43358 26.7738 9.74886V30.5569C26.7738 30.8722 26.5374 31.1087 26.2221 31.1087Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M51.5228 31.1086H26.2221C25.9068 31.1086 25.6704 30.8722 25.6704 30.5569C25.6704 30.2417 25.9068 30.0052 26.2221 30.0052H51.5228C51.8381 30.0052 52.0746 30.2417 52.0746 30.5569C52.0746 30.8722 51.8381 31.1086 51.5228 31.1086Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M23.9359 13.4533H20.7832C20.4679 13.4533 20.2314 13.2168 20.2314 12.9016C20.2314 12.5863 20.4679 12.3499 20.7832 12.3499H23.9359C24.2512 12.3499 24.4877 12.5863 24.4877 12.9016C24.4877 13.2168 24.2512 13.4533 23.9359 13.4533Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M23.9359 17.0789H20.7832C20.4679 17.0789 20.2314 16.8425 20.2314 16.5272C20.2314 16.2119 20.4679 15.9755 20.7832 15.9755H23.9359C24.2512 15.9755 24.4877 16.2119 24.4877 16.5272C24.4877 16.8425 24.2512 17.0789 23.9359 17.0789Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M23.9359 20.7046H20.7832C20.4679 20.7046 20.2314 20.4681 20.2314 20.1529C20.2314 19.8376 20.4679 19.6011 20.7832 19.6011H23.9359C24.2512 19.6011 24.4877 19.8376 24.4877 20.1529C24.4877 20.4681 24.2512 20.7046 23.9359 20.7046Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M23.9359 24.4091H20.7832C20.4679 24.4091 20.2314 24.1726 20.2314 23.8573C20.2314 23.5421 20.4679 23.3056 20.7832 23.3056H23.9359C24.2512 23.3056 24.4877 23.5421 24.4877 23.8573C24.4877 24.0938 24.2512 24.4091 23.9359 24.4091Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M23.9359 28.0347H20.7832C20.4679 28.0347 20.2314 27.7983 20.2314 27.483C20.2314 27.1677 20.4679 26.9313 20.7832 26.9313H23.9359C24.2512 26.9313 24.4877 27.1677 24.4877 27.483C24.4877 27.7194 24.2512 28.0347 23.9359 28.0347Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M46.1631 36.3106C45.8478 36.3106 45.6113 36.0742 45.6113 35.7589V32.6062C45.6113 32.2909 45.8478 32.0544 46.1631 32.0544C46.4783 32.0544 46.7148 32.2909 46.7148 32.6062V35.7589C46.7148 35.9954 46.4783 36.3106 46.1631 36.3106Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M42.4585 36.3106C42.1432 36.3106 41.9067 36.0742 41.9067 35.7589V32.6062C41.9067 32.2909 42.1432 32.0544 42.4585 32.0544C42.7738 32.0544 43.0102 32.2909 43.0102 32.6062V35.7589C43.089 35.9954 42.7738 36.3106 42.4585 36.3106Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M38.8329 36.3106C38.5177 36.3106 38.2812 36.0742 38.2812 35.7589V32.6062C38.2812 32.2909 38.5177 32.0544 38.8329 32.0544C39.1482 32.0544 39.3847 32.2909 39.3847 32.6062V35.7589C39.4635 35.9954 39.1482 36.3106 38.8329 36.3106Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M35.207 36.3106C34.8917 36.3106 34.6553 36.0742 34.6553 35.7589V32.6062C34.6553 32.2909 34.8917 32.0544 35.207 32.0544C35.5223 32.0544 35.7588 32.2909 35.7588 32.6062V35.7589C35.7588 35.9954 35.5223 36.3106 35.207 36.3106Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M31.5818 36.3106C31.2665 36.3106 31.03 36.0742 31.03 35.7589V32.6062C31.03 32.2909 31.2665 32.0544 31.5818 32.0544C31.897 32.0544 32.1335 32.2909 32.1335 32.6062V35.7589C32.1335 35.9954 31.897 36.3106 31.5818 36.3106Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M33.0789 28.5864H29.3744C29.0592 28.5864 28.8228 28.35 28.8228 28.0347V17.7883C28.8228 17.473 29.0592 17.2366 29.3744 17.2366H33.0789C33.3942 17.2366 33.6307 17.473 33.6307 17.7883V27.9559C33.7095 28.2712 33.3942 28.5864 33.0789 28.5864ZM30.005 27.4042H32.5272V18.4188H30.005V27.4042Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M41.2762 28.5864H37.5718C37.2565 28.5864 37.02 28.35 37.02 28.0347V21.7292C37.02 21.414 37.2565 21.1775 37.5718 21.1775H41.2762C41.5915 21.1775 41.828 21.414 41.828 21.7292V28.0347C41.828 28.2712 41.5915 28.5864 41.2762 28.5864ZM38.1235 27.4042H40.6457V22.2021H38.1235V27.4042Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
      <path
        d="M49.3944 28.5864H45.6899C45.3746 28.5864 45.1382 28.35 45.1382 28.0347V15.3449C45.1382 15.0297 45.3746 14.7932 45.6899 14.7932H49.3944C49.7097 14.7932 49.9461 15.0297 49.9461 15.3449V28.0347C50.025 28.2712 49.7097 28.5864 49.3944 28.5864ZM46.3205 27.4042H48.8427V15.8967H46.3205V27.4042Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeWidth="0.5"
      />
    </svg>
  );
};
