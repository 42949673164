import ReactGA from 'react-ga';

/** Just for sake of testing. These ID must be get from an environment variable */
const MEASUREMENT_ID = 'UA-184120392-1';

export const init = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export const sendPageView = (path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};
