import React, { useEffect } from 'react';
import './Policy.css';

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="policy" id="policy">
      <div className="max-width">
        <div className="policy-content">
          <div className="header">
            <h1>Privacy</h1>
            <p>
              Systematic Alpha is committed to protecting the privacy and
              security of the personal information that is provided to us or
              collected by us during the course of our business. We process
              personal information in accordance with the Swiss Data Protection
              Act and to the extent applicable the EU General Data Protection
              Regulation.
            </p>
            <p>
              This privacy policy explains how we may collect and use any
              personal information that we obtain about you and your rights in
              relation to that information.
            </p>
          </div>

          <div className="main-content">
            <h4>Use of our website</h4>
            <p>
              Cookies are small text files that are placed in browser
              directories on your computer or mobile device when you visit our
              website. Our website uses session cookies and persistent cookies.
              Session cookies enable you to move from page to page within the
              website and any information you enter to be remembered. A session
              cookie is deleted when you close your browser or after a short
              time. Persistent cookies allow the website to remember your
              preferences and settings when you visit the website in the future.
              Persistent cookies expire after a set period of time.
            </p>
            <ol className="dashed">
              <li>
                <span>Google Analytics: </span>We use Google Analytics which
                provides us with information about how visitors use our website.
                Google uses cookies as part of the process to collect anonymous
                information, such as the number of visitors to the website,
                where they are from, the pages they visit and the length of time
                they have spent on our website.
              </li>
              <li>
                <span>Career site:</span> When you access our career site, a
                session cookie will be placed on your computer. The cookie
                assists in the online application process and will be deleted
                when you close your browser.
              </li>
              <li>
                <span>Email updates:</span> Our marketing communications use
                cookies and tracking technology which helps us to assess whether
                the email updates have been opened, replied, forwarded and links
                followed as well as to allow you to contact us via an online
                contact form.
              </li>
            </ol>
          </div>
        </div>

        <div className="sub-content">
          <h4>How we collect and use your personal information</h4>

          <p>We collect and process your personal information as follows:</p>
          <p>
            <span>
              a. Business development, business acceptance and legal updates
            </span>
          </p>

          <p>
            We collect personal information about prospective clients, their
            representatives and beneficial owners as well as other business
            contacts as part of business development initiatives and our
            business acceptance process. The type of personal information we may
            collect includes name, contact details (such as your address, email
            address and telephone numbers), nationality, business interests,
            employment history and interactions with us.
          </p>
          <p>
            We obtain this information directly from you, from other business
            contacts, or from publicly available open sources.
          </p>
          <p>
            We may use your personal information to maintain and develop our
            business relationship with you, identify services you may be
            interested in, to pursue certain business development initiatives,
            send you publications and marketing communications, invite you to
            events and to comply with our legal obligations to identify and
            verify the identity of our clients.
          </p>
          <p>
            You can control the information you receive through our marketing
            communications by using the "Manage Subscriptions" option at the
            bottom of our emails. If you no longer wish to receive emails
            relating to our services, legal developments or events, you can
            unsubscribe at any time by using the "Unsubscribe" option at the
            bottom of the email or by contacting Client Service.
          </p>
          <p>
            Where we need to collect information required for our engagement and
            you fail to provide that information when requested, we may not be
            able to start working for you.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Policy;
