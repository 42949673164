import React from 'react';

const LinkedIn = (props) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="23" r="19" stroke="#46D0D6" />
      <path
        d="M20.9763 17.625V29.4974H24.6633V23.6262C24.6633 22.077 24.9549 20.5767 26.8762 20.5767C28.7711 20.5767 28.7945 22.3479 28.7945 23.724V29.4984H32.4836V22.9876C32.4836 19.7894 31.7949 17.3315 28.0559 17.3315C26.2608 17.3315 25.0576 18.3164 24.5655 19.2485H24.5156V17.625H20.9763ZM14.9678 17.625H18.6607V29.4974H14.9678V17.625Z"
        fill="#46D0D6"
      />
    </svg>
  );
};

export default LinkedIn;
