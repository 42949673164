import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { init, sendPageView } from './analytics';

export const useGoogleAnalytics = () => {
  /**  It is the only way to access the current location since the App Component is using
   *** <BrowserRouter /> that handles the history automatically
   */
  const location = useLocation();

  /** Initializes the Google Analytics when Component mounts for the first time*/
  useEffect(() => {
    init();
  }, []);

  /** Sends the pageview every time the location changes */
  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}`;
    sendPageView(currentPath);
  }, [location]);
};
