import React, { useEffect } from 'react';
import DoubleRay from '../../svg/DoubleRay';
import HexagonIcon from '../../svg/HexagonIcon';
import TimeLineIco from '../../svg/TimeLineIco';
import Card from './Card';

const MainSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="investment-main-section">
      <div className="max-width">
        <div className="grid-container">
          <div className="text-box">
            <h2>Investment Approach</h2>
            <p>
            Every strategy starts with an idea, but that’s only part of the story. Success depends on the pursuit of ideas backed by rigorous research and the latest technology. We combine world class expertise, unbeatable experience and the latest technology to unlock opportunities locked within market data.
            </p>
            <br/>
            <p>
            Our approach is a combination of the old and the new. We use technology to compliment the power of our team underpinning rigorous research, qualitative analytics and a state-of-the-art platform. This ensures a sustainable cycle of positive strategies and ongoing returns.
            </p>
          </div>        
          
          <Card
            cardNumber={'card-3'}
            title={'EXECUTION'}
            text={
              'Our proprietary platform uses advanced algorithms backed with smart order rooting to execute multiple types of trades, improve transparency and reduce costs.'
            }
          >
            <TimeLineIco className="timeline-ico" />
          </Card>
          <Card
            cardNumber={'card-4'}
            title={'RISK MANAGEMENT'}
            text={
              'We manage risk through diversifying relying on multiple small investments. No single individual makes the decision ensuring decisions are driven by consensus and the data.'
            }
          >
            <TimeLineIco className="timeline-ico" />
          </Card>
          <Card
            cardNumber={'card-5'}
            title={'PROPRIETARY PLATFORM'}
            text={
              'We’ve developed a proven proprietary platform which draws on advanced algorithms and automation to unlock hidden opportunities and enhance our analysis.'
            }
          >
            <DoubleRay className="double-ray-ico" />
          </Card>
          <Card
            cardNumber={'card-6'}
            title={'RESEARCH AND DEVELOPMENT'}
            text={
              'Everything we do is backed by the fundamentals of research and development. We are constantly developing new algorithms and reviewing our approach to deliver superior insights and maintain quality.'
            }
          >
            <HexagonIcon className="hexagon-icon" />
          </Card>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
