import React, { useState } from 'react';

import LeftSideNavBar from './LeftSideNavBar';
import StrategiesSubPageRenderer from './StrategiesSubPageRenderer';

const StrategiesInsightSession = () => {
  const [activePage, setActivePage] = useState('scanning-market');

  const setActivePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  return (
    <section className="strategies-insight">
      <div className="max-width">
        <h1>strategies insight</h1>
        <p>
        Our success relies on a disciplined adherence to proven investment strategies.
        </p>
        <div className="strategies-insight-content">
          <LeftSideNavBar
            activePage={activePage}
            onClickHandler={setActivePageHandler}
          />
          <StrategiesSubPageRenderer activePage={activePage} />
        </div>
      </div>
    </section>
  );
};

export default StrategiesInsightSession;
