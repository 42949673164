import React from 'react';

const StrategiesSubPage = ({ text }) => {
  return (
    <div className="strategies-subpage">
      <div className="subpage-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default StrategiesSubPage;
