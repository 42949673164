import React, { useState } from 'react';
import axios from 'axios';
import ArrowRightSmall from '../../svg/ArrowRightSmall';

const initialState = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
};

const clearInputFields = () => {
  document.getElementById('section-contact').reset();
};

const sendContact = (contactInfo) => {
  axios
    .post('/api/contact', contactInfo)
    .then((res) => console.log(res.data))
    .catch((error) => console.log(error.response));
};

const ContactForm = () => {
  const [contactData, setContactData] = useState(initialState);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    /** Uses axios to make a Post request */
    sendContact(contactData);

    setTimeout(() => {
      clearInputFields();
    }, 2500);
  };

  const onChangeHandler = (event) => {
    setContactData({ ...contactData, [event.target.name]: event.target.value });
  };

  return (
    <div className="container">
      <div className="header">
        <h1>Get In Touch</h1>
        <p>feel free to drop us a line</p>
      </div>
      <form onSubmit={(event) => onSubmitHandler(event)} id="section-contact">
        {/** First row of the contact form */}
        <div className="form-row">
          <div className="input-data">
            <input
              type="text"
              name="name"
              onChange={(event) => onChangeHandler(event)}
              required
            />
            <div className="underline"></div>
            <label htmlFor="name">Name *</label>
          </div>
          <div className="input-data">
            <input
              type="email"
              name="email"
              onChange={(event) => onChangeHandler(event)}
              required
            />
            <div className="underline"></div>
            <label htmlFor="email">Email *</label>
          </div>
        </div>

        {/** Second row of the contact form */}
        <div className="form-row">
          <div className="input-data">
            <input
              type="text"
              name="phone"
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="underline"></div>
            <label htmlFor="name">Phone</label>
          </div>
          <div className="input-data">
            <input
              type="text"
              name="subject"
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="underline"></div>
            <label htmlFor="email">Subject</label>
          </div>
        </div>

        {/** Third row of the contact form (Text Area)*/}
        <div className="form-row">
          <div className="input-data textarea">
            <textarea
              rows="8"
              cols="80"
              name="message"
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="underline"></div>
            <label htmlFor="message">Message</label>
          </div>
        </div>

        {/** Last row of the contact form (Submit Button)*/}
        <div className="form-row submit-btn">
          <div className="input-data">
            <button type="submit">Submit</button>
            <i>
              <ArrowRightSmall className="arrow-right-small" />
            </i>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
