import React from 'react';

const TextContent = ({ title, leftText, rightText }) => {
  return (
    <React.Fragment>
      <section className="mission">
        <div className="max-width">
          <div className="mission-content">
            <h3 className="heading">{title}</h3>
            <div className="text-block">
              <p className="text">{leftText}</p>
              <p className="text">{rightText}</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TextContent;
