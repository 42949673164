import React from 'react';
import TextContent from './TextContent';
import './Mission.css';
import SectionImage from './SectionImage';

const Mission = () => {
  return (
    <React.Fragment>
      <TextContent
        title={'Mission'}
        leftText={
          'Our mission is to offer long term gains which go far beyond the short term. We do this with data driven technology based on advanced algorithms and automation.'
        }
        rightText={
          'They compliment the in depth expertise of our team and allow us to carefully craft sustainable, uncorrelated gains.'
        }
      />

      <SectionImage />

      <TextContent
        title={'Technology'}
        leftText={
          'We rely on our own proprietary trading system to deliver results. Harnessing the latest in technological innovation, it allows us to stay deeply informed on thousands of different securities. We’ve developed this technology ourselves to work in conjunction with our world class investment experts. Our execution to model approach simulates performance and predefined trading costs to improve results. It helps us to see where the market is going, simulate strategies and design the best approach possible. The model uses slippage as reference and shows actual performance against the model allowing us to constantly assess our strategies.'
        }
        rightText={
          'At Systemic Alpha we work where technology meets finance. We’ve developed market leading alternative investment strategies based on our own cutting-edge technology and world class expertise. Our in depth research and algorithmic trading allows us to dive into the data delivering superior insights and maximising analytical capabilities. The result is a platform which offers superior capabilities, sustainable rewards and lower pricing than any of our competitors. Our approach avoids hidden costs, maintains total transparency and gives you a crucial advantage in volatile markets.'
        }
      />
    </React.Fragment>
  );
};

export default Mission;
