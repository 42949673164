import React from 'react';
import './SectionImage.css';

const SectionImage = () => {
  return (
    <section className="section-image">
      <div className="max-width"></div>
    </section>
  );
};

export default SectionImage;
