import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Careers from './components/Careers/Careers';
import Contact from './components/ContactPage/Contact';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import InvestmentApproach from './components/InvestmentApproach/InvestmentApproach';
import Navbar from './components/Navbar';
import SubFooter from './components/SubFooter';
import WhoWeAre from './components/WhoAreWe/WhoWeAre';
import { CookiesProvider, useCookies } from 'react-cookie';
import CookiePopUp from './components/CookiePopUp';
import Policy from './components/Privacy/Policy';
import NotFoundPage from './components/NotFound/NotFoundPage';
import { useGoogleAnalytics } from './useGoogleAnalytics';

/** This Component is a quick fix for the hook dependency
 ** (current location) not available on App Components */
function AllRoutes() {
  useGoogleAnalytics();

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/who-we-are">
        <WhoWeAre />
      </Route>
      <Route path="/careers">
        <Careers />
      </Route>
      <Route path="/investment-approach">
        <InvestmentApproach />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/policy">
        <Policy />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

function App() {
  /** All the logic below refers to setting the consent cookie in the cookie storage.
   *
   * It basically, only renders the popup (in all the pages) if the value
   * of the cookies.gpdr_consent is true. Once the "Got It" button
   * is clicked, it sets the cookie value to true, the shouldShowPopUp
   * function renders or not the component depending of its value */
  const [cookies, setCookie] = useCookies(['gpdr_consent']);
  const [isConsent, setIsConsent] = useState(false);

  useEffect(() => {
    if (isConsent) {
      setCookie('gpdr_consent', true, {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });
    }
  }, [isConsent, setCookie]);

  const shouldShowPopUp = () => {
    return (
      !cookies.gpdr_consent && (
        <CookiePopUp isConsent={isConsent} setIsConsent={setIsConsent} />
      )
    );
  };

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Navbar />
        <AllRoutes />
        <SubFooter />
        <Footer />
        {shouldShowPopUp()}
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
