import React from 'react';
import { CogWheelIcon } from '../../svg/CogWheelIcon';
import GroupChart from '../../svg/GroupChart';
import { RetangleChartIcon } from '../../svg/RetangleChartIcon';
import Card from './Card';
import './VisionCard.css';

const VisionCards = () => {
  return (
    <section className="vision-cards">
      <div className="max-width">
        <div className="vision-cards-content">
          <Card
            title={'Science'}
            description={
              'We believe in the power of technology and data to deliver value. Because we’ve developed our own proprietary platform based on the latest advances in algorithmic trading, we can deliver superior market insights. We believe in the power of science backed by the expertise of our team. Our use of technology allows us to crunch the numbers and take a deep dive into the data. Meanwhile, our use of world class experts allows us to back the data up with expert intuition. The result is a system which identifies trends and insights others may miss.'
            }
          >
            <GroupChart className="group-chart-icon" />
          </Card>

          <Card
            title={'Technique'}
            description={
              'Our strategies are designed to help us maximise the value we offer our clients. It is this blend of advanced computing and good old-fashioned expertise which underpins our approach. We use technology to deliver data driven strategies and to reveal hidden trends.'
            }
          >
            <CogWheelIcon className="cogwheel-ico" />
          </Card>

          <Card
            title={'Research'}
            description={
              'The market is constantly evolving and so is our approach. We regularly review our algorithms to increase AUM and ensure a carefully planned and mapped out strategy. No trading strategy depends on one key person which makes it less vulnerable to a single individual’s mistakes. Instead we draw on the collective expertise and talents of the entire team.'
            }
          >
            <RetangleChartIcon className="retangle-chart-icon" />
          </Card>
        </div>
      </div>
    </section>
  );
};

export default VisionCards;
