import React, { useEffect } from 'react';
import './SubFooter.css';
import { NavLink } from 'react-router-dom';
import logo from '../images/sa-light-logo.png';

const SubFooter = () => {
  /** Forces the page to scroll to the top every the click event points to
   * the same page it already is  */
  useEffect(() => {
    const subFooterMenuItems = document.querySelectorAll('.text p a.link');

    subFooterMenuItems.forEach((link) =>
      link.addEventListener('click', () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      })
    );
  }, []);

  return (
    <section className="sub-footer">
      <div className="max-width">
        <div className="sub-footer-content">
          <div className="card left-top">
            <div className="box box-top">
              <img
                src={logo}
                className="alpha-logo"
                alt="Systematic Alpha Oy"
              />
            </div>
            <div className='box'>
              <div className='text small-notify'>
                {' '}
                <p>Tähän rahoitustuotteeseen sisältyvissä</p>
                <p>sijoituksissa ei oteta huomioon</p>
                <p>ympäristön kannalta kestäviä taloudellisia</p>
                <p>toimintoja koskevia EU:n kriteerejä.</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box box-top">
              <div className="text">
                <p>Töölönkatu 28 A 26,</p>
                <p>00260 Helsinki</p>
                <p>Finland</p>
              </div>
            </div>
            <div className="box box-bottom">
              <div className="text">
                {' '}
                <p>info@systematicalpha.fi</p>
                <p>+35 850 520 83 09</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="box">
              <div className="text items">
                <p>
                  <NavLink to="/who-we-are" className="link">
                    {' '}
                    Who We Are
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/investment-approach" className="link">
                    {' '}
                    Investment Approach
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/careers" className="link">
                    {' '}
                    Careers
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/contact" className="link">
                    {' '}
                    Contact Us
                  </NavLink>
                </p>
                <p>
                  <a className="link" href="https://monitor.systematicalpha.fi">
                    Investor Login
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="card right-bottom">
            <div className="box">
              <a
                href="https://www.linkedin.com/company/systematic-alpha"
                rel="noreferrer"
                target="_blank"
              >
                <i className="linked-in"></i>
              </a>              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubFooter;
