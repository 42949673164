import React from 'react';
import interviewImg from '../../images/careers-interview-process.jpg';
import mentorshipImg from '../../images/careers-mentorship.jpg';

const MiddleSection = () => {
  return (
    <section className="middle">
      <div className="max-width">
        <div className="middle-content">
          <div className="item">
            <div className="info">
              <h1>Interview Process</h1>
              <p>
              Our hiring process is flexible and designed to identify people who would thrive in our environment. It generally consists of written assessments and interviews. To cater to life in the age of the pandemic many of our interviews take place remotely via Skype or another video conferencing software.
              </p>
            </div>
            <div className="middle-image">
              {' '}
              <img src={interviewImg} alt="example" />
            </div>
          </div>

          <div className="item">
            <div className="info">
              <h1>Mentorship</h1>
              <p>
              We believe in developing our human capital. By helping people evolve and fulfil their potential, everyone benefits. That’s why we include a comprehensive mentorship programme which helps people learn from others, grow their skills and expand their horizons. This is a place where you can grow with the job.
              </p>
            </div>

            <div className="middle-image">
              {' '}
              <img src={mentorshipImg} alt="example" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MiddleSection;
