import React, { useState } from 'react';
import DataEngineer from './pages/DataEngineer';
import QuantitativeResearcher from './pages/QuantitativeResearcher';
import Accordion from './Accordion';
import LeftSideMenu from './LeftSideMenu';
import PageRenderer from './PageRenderer';
import './PositionSection.css';

const PositionSection = () => {
  /**
   *  Defines which page will be rendered in the PageRenderer Component
   *  based in onClick event. Quantitative is the default page to be rendered */
  const [activePage, setActivePage] = useState('quantitative-researcher');
  const setActivePageHandler = (pageName) => {
    setActivePage(pageName);
  };

  return (
    <section className="position">
      <div className="max-width">
        <h1><a name="open-positions"></a>Open Positions</h1>
        <div className="position-content">
          <div className="description">
              <p>
              No open positions at the moment!
              </p>
            </div>
        </div>
      </div>
    </section>
  );
};

export default PositionSection;
