import React from 'react';

const LeftSideNavBar = ({ activePage, onClickHandler }) => {
  return (
    <div className="left-side-navbar-menu">
      <ol>
        <li
          className={activePage === 'scanning-market' ? 'active' : ''}
          onClick={() => {
            onClickHandler('scanning-market');
          }}
        >
          Scanning market universe
        </li>
        <li
          className={activePage === 'applying-filters' ? 'active' : ''}
          onClick={() => {
            onClickHandler('applying-filters');
          }}
        >
          Applying special filters
        </li>
        <li
          className={activePage === 'unique-combinations' ? 'active' : ''}
          onClick={() => {
            onClickHandler('unique-combinations');
          }}
        >
          Matching unique combination of model inputs
        </li>
        <li
          className={activePage === 'predictive-modeling' ? 'active' : ''}
          onClick={() => {
            onClickHandler('predictive-modeling');
          }}
        >
          Predictive modeling
        </li>
        <li
          className={activePage === 'portfolio-construction' ? 'active' : ''}
          onClick={() => {
            onClickHandler('portfolio-construction');
          }}
        >
          Portfolio construction
        </li>
      </ol>
    </div>
  );
};

export default LeftSideNavBar;
