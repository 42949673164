import React from 'react';

const ArrowRightSmall = (props) => {
  return (
    <svg
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.403777 0.398624C0.531445 0.272266 0.683112 0.172014 0.850086 0.103611C1.01706 0.0352085 1.19606 0 1.37684 0C1.55762 0 1.73662 0.0352085 1.9036 0.103611C2.07057 0.172014 2.22224 0.272266 2.34991 0.398624L10.5962 8.53971C10.7242 8.66575 10.8258 8.81548 10.895 8.98032C10.9643 9.14516 11 9.32188 11 9.50036C11 9.67883 10.9643 9.85555 10.895 10.0204C10.8258 10.1852 10.7242 10.335 10.5962 10.461L2.34991 18.6021C2.09183 18.8569 1.74181 19 1.37684 19C1.01187 19 0.66185 18.8569 0.403777 18.6021C0.145704 18.3473 0.000720901 18.0018 0.000720894 17.6414C0.000720887 17.2811 0.145704 16.9356 0.403777 16.6808L7.67978 9.50036L0.403777 2.31992C0.275785 2.19388 0.174238 2.04415 0.104952 1.87931C0.0356649 1.71446 0 1.53774 0 1.35927C0 1.1808 0.0356649 1.00408 0.104952 0.839237C0.174238 0.674393 0.275785 0.524664 0.403777 0.398624Z"
        fill="#46D0D6"
      />
    </svg>
  );
};

export default ArrowRightSmall;
