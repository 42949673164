import React from 'react';

const DoubleRay = (props) => {
  return (
    <svg
      width="31"
      height="40"
      viewBox="0 0 31 40"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 40H0.845847L15.628 18.1048C15.8294 17.7151 15.722 17.5202 15.3058 17.5202H9.20362L15.628 5.80645C15.816 5.41667 15.675 5.22177 15.2051 5.22177H7.02858C6.80034 5.22177 6.59223 5.34946 6.40427 5.60484L0.443063 21.5121C0.389358 21.9019 0.523619 22.0968 0.845847 22.0968H6.74664L0 40ZM18.2663 26.3911H18.8503L30.0678 9.89919C30.1752 9.72446 30.2021 9.58333 30.1484 9.47581C30.0947 9.36828 29.9739 9.31452 29.7859 9.31452H25.2747L29.9671 0.645161C30.2222 0.215054 30.0947 0 29.5845 0H23.7038C23.4219 0 23.2071 0.134409 23.0594 0.403226L18.5885 12.1976C18.5214 12.3858 18.5281 12.5336 18.6086 12.6411C18.6892 12.7487 18.8234 12.8024 19.0114 12.8024H23.4018L18.2663 26.3911Z"
        fill="#46D0D6"
      />
    </svg>
  );
};

export default DoubleRay;
