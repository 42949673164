import React from 'react';

const Card = ({ title, description, children }) => {
  return (
    <div className="card">
      <div className="box">
        <i>{children}</i>
        <div className="title">{title}</div>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default Card;
