import React from 'react';

const Header = ({ className, text }) => {
  return (
    <section className={className}>
      <div className="max-width">
        <div className={`${className}-content`}>
          <div className="text">{text}</div>
        </div>
      </div>
    </section>
  );
};

export default Header;
