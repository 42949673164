import React from 'react';
import StrategiesSubPage from './StrategiesSubPage';

const StrategiesSubPageRenderer = ({ activePage }) => {
  return (
    <div className="strategies-subpage-renderer">
      {activePage === 'scanning-market' && (
        <StrategiesSubPage
          text={
            'Our algorithms scan thousands of assets and analyses the data in real time. It allows us to unlock hidden trends and opportunities in the market.'
          }
        />
      )}

      {activePage === 'applying-filters' && (
        <StrategiesSubPage
          text={
            'Our system applies special filters to sort data, derive insights and ensure strategies are based on accurate real time data.'
          }
        />
      )}

      {activePage === 'unique-combinations' && (
        <StrategiesSubPage
          text={
            'We are constantly generating performance by matching real results against modelling.'
          }
        />
      )}

      {activePage === 'predictive-modeling' && (
        <StrategiesSubPage
          text={
            'Our proprietary platform uses advanced model simulation to assess and analyse strategies.'
          }
        />
      )}

      {activePage === 'portfolio-construction' && (
        <StrategiesSubPage
          text={
            'We construct portfolios based on our disciplined strategic approach. To manage risk, we focus on diversification maintaining multiple small trades to minimise risk exposure.'
          }
        />
      )}
    </div>
  );
};

export default StrategiesSubPageRenderer;
