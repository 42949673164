import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="not-found" id="not-found">
      <div className="max-width">
        <div className="not-found-content">
          <div className="not-found-header">
            <h1>Oops!</h1>
          </div>
          <h2>404 - Page Not Found</h2>
          <p>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <NavLink to="/">Go To Homepage</NavLink>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
