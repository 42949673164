import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowRight } from '../../svg/ArrowRight';

const FeelFreeSection = () => {
  return (
    <section className="feel-free-section">
      <div className="max-width">
        <div className="content">
          <div className="text">
            <p>
              <NavLink to="/contact">Feel free to contact us anytime </NavLink>
            </p>
            <NavLink to="/contact">
              {' '}
              <i>
                <ArrowRight className="arrow-right" />
              </i>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeelFreeSection;
