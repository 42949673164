import React from 'react';

const BoxText = ({ className, title, description, others }) => {
  return (
    <section className="square-box">
      <div className="max-width">
        <div className={`${className}-content`}>
          <div className="text-box">
            <p className="header">{title}</p>
            <p className="description">{description}</p>
            {others && <p className="description">{others}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxText;
