import React from 'react';

const GroupChart = (props) => {
  return (
    <svg
      width="67"
      height="60"
      viewBox="0 0 67 60"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2485 58.9729H5.83073C5.53794 58.9729 5.31836 58.7533 5.31836 58.4605V43.6013C5.31836 43.3085 5.53794 43.0889 5.83073 43.0889H14.2485C14.5413 43.0889 14.7609 43.3085 14.7609 43.6013V58.4605C14.8341 58.7533 14.5413 58.9729 14.2485 58.9729ZM6.41633 57.8749H13.7362V44.1136H6.41633V57.8749Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M29.8401 58.9729H21.4223C21.1295 58.9729 20.9099 58.7533 20.9099 58.4605V22.5202C20.9099 22.2274 21.1295 22.0078 21.4223 22.0078H29.8401C30.1329 22.0078 30.3525 22.2274 30.3525 22.5202V58.4605C30.4257 58.7533 30.1329 58.9729 29.8401 58.9729ZM22.0079 57.8749H29.3277V23.0326H22.0079V57.8749Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M45.5044 58.9729H37.0866C36.7938 58.9729 36.5742 58.7533 36.5742 58.4606V26.8389C36.5742 26.5461 36.7938 26.3265 37.0866 26.3265H45.5044C45.7972 26.3265 46.0168 26.5461 46.0168 26.8389V58.4606C46.0168 58.7533 45.7972 58.9729 45.5044 58.9729ZM37.599 57.875H44.9188V27.2781H37.599V57.875Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M61.0957 58.9729H52.6779C52.3851 58.9729 52.1655 58.7533 52.1655 58.4605V16.884C52.1655 16.5912 52.3851 16.3716 52.6779 16.3716H61.0957C61.3885 16.3716 61.6081 16.5912 61.6081 16.884V58.4605C61.6081 58.7533 61.3885 58.9729 61.0957 58.9729ZM53.2635 57.875H60.5833V17.3232H53.2635V57.875Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M44.1867 16.8838C44.0403 16.8838 43.8207 16.8107 43.7475 16.6643C43.6011 16.4447 43.6011 16.0787 43.8939 15.9323L59.046 5.24533C59.2655 5.09893 59.6315 5.09894 59.7779 5.39173C59.9243 5.61132 59.9243 5.97732 59.6315 6.12371L44.4795 16.8106C44.4063 16.8106 44.3331 16.8838 44.1867 16.8838Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M39.7952 17.25C39.722 17.25 39.6488 17.25 39.5756 17.1768L26.7659 11.0282C26.4731 10.8818 26.3999 10.589 26.5463 10.2962C26.6927 10.0034 26.9855 9.93022 27.2783 10.0766L40.088 16.2253C40.3808 16.3717 40.454 16.6645 40.3076 16.9572C40.1612 17.1036 39.9416 17.25 39.7952 17.25Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M7.51461 23.545C7.36821 23.545 7.22181 23.4718 7.07542 23.3254C6.92902 23.1058 6.92902 22.7398 7.14862 22.5934L22.3006 10.5889C22.5202 10.3693 22.8862 10.4425 23.0326 10.6621C23.2522 10.8817 23.179 11.2477 22.9594 11.3941L7.8074 23.3986C7.7342 23.545 7.58781 23.545 7.51461 23.545Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.46514 27.7906C3.78158 27.7906 2.31763 26.3999 2.31763 24.6431C2.31763 22.9596 3.70838 21.4956 5.46514 21.4956C7.1487 21.4956 8.61268 22.8864 8.61268 24.6431C8.61268 26.3999 7.2219 27.7906 5.46514 27.7906ZM5.46514 22.5936C4.36717 22.5936 3.4156 23.5452 3.4156 24.6431C3.4156 25.7411 4.36717 26.6927 5.46514 26.6927C6.56311 26.6927 7.5147 25.7411 7.5147 24.6431C7.5147 23.5452 6.63631 22.5936 5.46514 22.5936Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M24.6429 12.5653C22.9593 12.5653 21.4954 11.1745 21.4954 9.41779C21.4954 7.73423 22.8861 6.27026 24.6429 6.27026C26.3996 6.27026 27.7904 7.66103 27.7904 9.41779C27.7904 11.1013 26.3996 12.5653 24.6429 12.5653ZM24.6429 7.36824C23.5449 7.36824 22.5933 8.31982 22.5933 9.41779C22.5933 10.5158 23.5449 11.4673 24.6429 11.4673C25.7408 11.4673 26.6924 10.5158 26.6924 9.41779C26.6924 8.31982 25.814 7.36824 24.6429 7.36824Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M42.064 20.9099C40.3805 20.9099 38.9165 19.5192 38.9165 17.7624C38.9165 16.0788 40.3073 14.6149 42.064 14.6149C43.7476 14.6149 45.2116 16.0056 45.2116 17.7624C45.2116 19.5192 43.8208 20.9099 42.064 20.9099ZM42.064 15.786C40.966 15.786 40.0145 16.7376 40.0145 17.8356C40.0145 18.9336 40.966 19.8851 42.064 19.8851C43.162 19.8851 44.1136 18.9336 44.1136 17.8356C44.1136 16.6644 43.2352 15.786 42.064 15.786Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M61.462 7.29504C59.7784 7.29504 58.3145 5.90428 58.3145 4.14752C58.3145 2.46396 59.7052 1 61.462 1C63.1455 1 64.6095 2.39076 64.6095 4.14752C64.6095 5.83108 63.2187 7.29504 61.462 7.29504ZM61.462 2.09797C60.364 2.09797 59.4124 3.04955 59.4124 4.14752C59.4124 5.24549 60.364 6.19707 61.462 6.19707C62.5599 6.19707 63.5115 5.24549 63.5115 4.14752C63.5115 3.04955 62.6331 2.09797 61.462 2.09797Z"
        fill="#46D0D6"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
      <path
        d="M65.4876 58.973H1.51237C1.21958 58.973 1 58.7534 1 58.4606C1 58.1678 1.21958 57.9482 1.51237 57.9482H65.4876C65.7804 57.9482 66 58.1678 66 58.4606C66 58.7534 65.7804 58.973 65.4876 58.973Z"
        fill="#1B212C"
        stroke="#46D0D6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default GroupChart;
