import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import darkLogoImg from '../images/sa-dark-logo.png';
import lightLogoImg from '../images/sa-light-logo.png';
import LinkedIn from '../svg/LinkedIn';
import UserIcon from '../svg/UserIcon';

import './Navbar.css';

const Navbar = () => {
  /** Use Location hook gets the current route */
  const location = useLocation();
  const [navClassName, setNavClassName] = useState('navbar');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isToggle, setIsToggle] = useState(true);

  /** Sets the color of the menu to black only if the background is light,
   * for example in the Get In Touch Page AND if the value of the scroll position is
   * less than 20 because for values greater than that, the navbar becames "sticky" and
   * has dark background */
  const menuClassStyle =
    (location.pathname === '/contact' && scrollPosition <= 20) ||
    (scrollPosition <= 20 && location.pathname === '/policy')
      ? 'navbar-link navbar-link-light'
      : 'navbar-link';

  /** Renders the logo depending in which page is being visited.
   *
   * Example, for pages with dark background (Policy and Contact Us),
   * it renders a light color logo and vice-versa    */
  const logoRender =
    (location.pathname === '/contact' && scrollPosition <= 20) ||
    (scrollPosition <= 20 && location.pathname === '/policy')
      ? darkLogoImg
      : lightLogoImg;

  /** Sets the navbar className according with the value of Y scroll position */
  const onScrollHandler = () => {
    let scrolledYValue = document.scrollingElement.scrollTop;
    setScrollPosition(scrolledYValue);

    if (scrolledYValue >= 20) {
      setNavClassName('navbar sticky');
    } else {
      setNavClassName('navbar');
    }
  };

  /** Toggles the hamburger menu along with the ternary operation condition */
  const onToggleHandler = () => {
    setIsToggle(!isToggle);
  };

  /** This useEffect sticks or not the mobile navbar depending on the scroll Y value */
  useEffect(() => {
    document.addEventListener('scroll', onScrollHandler);
  }, []);

  /** Forces the page to scroll to the top every the click event points to
   * the same page it already is */
  useEffect(() => {
    const allMenuLinks = document.querySelectorAll('.navbar .menu li a');

    allMenuLinks.forEach((menuLink) =>
      menuLink.addEventListener('click', () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      })
    );
  }, []);

  return (
    <nav className={navClassName}>
      <div className="max-width">
        <div className="logo">
          <NavLink exact to="/">
            <img
              src={logoRender}
              className="logo-img"
              alt="Systematic Alpha Oy"
            />
          </NavLink>
        </div>
        <ul className={isToggle ? 'menu navbar-link' : 'menu active'}>
          <li>
            <NavLink
              to="/who-we-are"
              className={menuClassStyle}
              activeClassName="navbar-link-active"
              onClick={() => onToggleHandler()}
            >
              Who We Are
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/investment-approach"
              className={menuClassStyle}
              activeClassName="navbar-link-active"
              onClick={() => onToggleHandler()}
            >
              Investment Approach
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/careers"
              className={menuClassStyle}
              activeClassName="navbar-link-active"
              onClick={() => onToggleHandler()}
            >
              Careers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={menuClassStyle}
              activeClassName="navbar-link-active"
              onClick={() => onToggleHandler()}
            >
              Contact Us
            </NavLink>
          </li>
          <li>
            <a className={menuClassStyle} href="https://monitor.systematicalpha.fi">
              Investor Login
              <i className="user-icon">
                <UserIcon />
              </i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/systematic-alpha"
              rel="noreferrer"
              target="_blank"
              onClick={() => onToggleHandler()}
            >
              <i className="linked-in"></i>
            </a>
          </li>
        </ul>
        <div className="icons-wrapper">
          <div
            className={isToggle ? 'linkedin' : 'linkedin active'}
            onClick={() => onToggleHandler()}
          >
            <a href="https://www.linkedin.com/company/systematic-alpha" rel="noreferrer" target="_blank">
              <LinkedIn />
            </a>
          </div>
          <div
            className={isToggle ? 'hamburger' : 'hamburger active'}
            onClick={() => onToggleHandler()}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
