import React, { useEffect } from 'react';
import VisionCards from './VisionCards';
import Mission from './Mission';
import FeelFreeSection from './FeelFreeSection.jsx';
import './Header.css';
import './BoxText.css';
import Header from '../common/Header';
import BoxText from '../common/BoxText';
import './FeelFreeSection.css';

const WhoWeAre = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Header className={'who-are-we'} text={'Who We Are'} />
      <BoxText
        className={'our-vision'}
        title={'Our Vision'}
        description={
          'At Systematic Alpha we know that trading can be complicated. We’re a registered alternative investment management firm dedicated to helping you navigate this complex world. We believe in diversifying risk and focusing on absolute investment return strategies to deliver strong uncorrelated returns over time.'
        }
      />

      <VisionCards />
      <Mission />
      <FeelFreeSection />
    </React.Fragment>
  );
};

export default WhoWeAre;
