import React from 'react';
import Header from '../common/Header';
import './InvestmentApproach.css';
import BoxText from '../common/BoxText';
import FeelFreeSection from './FeelFreeSection';
import StrategiesInsightSession from './StrategiesInsightSession';
import MainSection from './MainSection';

const InvestmentApproach = () => {
  return (
    <React.Fragment>
      <Header className={'investment-approach'} text={'Investment Approach'} />
      <BoxText
        className={'overview'}
        title={'Overview'}
        description={
          'Systemic Alpha is a world class algorithmic alternative investment firm drawing on the latest scientific association combined with in depth analytics and a data driven approach. With our commitment to risk management and our own proprietary technology we are committed to delivering long term sustainable gains.'
        }
        others={
          'Harnessing complex algorithms and automated technology backed by rigorous research, we focus on unlocking hidden opportunities. Our data driven platform provides granular insights to identify trends which might otherwise be hidden. It is our combination of market expertise and technological excellence which enables us to deliver long term gains, low risk and transparent pricing.'
        }
      />
      <MainSection />
      <StrategiesInsightSession />
      <FeelFreeSection />
    </React.Fragment>
  );
};

export default InvestmentApproach;
