import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Home.css';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="home" id="home">
      <div className="max-width">
        <div className="home-content">
          <div className="text-1">
             World class <span>expertise &amp; technology</span>
          </div>
          <div className="text-2">for strong, sustainable gains.</div>
          <NavLink to="/investment-approach">Learn More</NavLink>
        </div>
      </div>
    </section>
  );
};

export default Home;
