import React, { useEffect } from 'react';
import './Contact.css';
import ContactForm from './ContactForm';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="contact" id="contact">
      <div className="background-wrapper"></div>
      <div className="max-width">
        <ContactForm />
      </div>
    </section>
  );
};

export default Contact;
