import React, { useEffect } from 'react';
import './CookiePopUp.css';
import { NavLink } from 'react-router-dom';

const CookiePopUp = ({ setIsConsent, isConsent }) => {
  const onClickScrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    onClickScrollToTop();
  }, []);

  return (
    <div className="popup-container">
      <div className="modal">
        <p>
          Cookies help us deliver our services. By using our services, you agree
          to our use of cookies.
        </p>
        <div className="buttons">
          <NavLink to="/policy" onClick={() => onClickScrollToTop()}>
            Learn More
          </NavLink>
          <button
            className="accept-btn"
            onClick={() => {
              setIsConsent(!isConsent);
            }}
          >
            Got It!
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopUp;
