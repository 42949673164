import React from 'react';

export const ArrowRight = (props) => {
  return (
    <svg
      width="48"
      height="19"
      viewBox="0 0 48 19"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4038 0.398624C37.5314 0.272266 37.6831 0.172014 37.8501 0.103611C38.0171 0.0352085 38.1961 0 38.3768 0C38.5576 0 38.7366 0.0352085 38.9036 0.103611C39.0706 0.172014 39.2222 0.272266 39.3499 0.398624L47.5962 8.53971C47.7242 8.66575 47.8258 8.81548 47.895 8.98032C47.9643 9.14516 48 9.32188 48 9.50036C48 9.67883 47.9643 9.85555 47.895 10.0204C47.8258 10.1852 47.7242 10.335 47.5962 10.461L39.3499 18.6021C39.0918 18.8569 38.7418 19 38.3768 19C38.0119 19 37.6618 18.8569 37.4038 18.6021C37.1457 18.3473 37.0007 18.0018 37.0007 17.6414C37.0007 17.2811 37.1457 16.9356 37.4038 16.6808L44.6798 9.50036L37.4038 2.31992C37.2758 2.19388 37.1742 2.04415 37.105 1.87931C37.0357 1.71446 37 1.53774 37 1.35927C37 1.1808 37.0357 1.00408 37.105 0.839237C37.1742 0.674393 37.2758 0.524664 37.4038 0.398624Z"
        fill="#1B212C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.5C0 9.10218 0.215295 8.72064 0.598521 8.43934C0.981748 8.15804 1.50151 8 2.04348 8H44.9565C45.4985 8 46.0183 8.15804 46.4015 8.43934C46.7847 8.72064 47 9.10218 47 9.5C47 9.89782 46.7847 10.2794 46.4015 10.5607C46.0183 10.842 45.4985 11 44.9565 11H2.04348C1.50151 11 0.981748 10.842 0.598521 10.5607C0.215295 10.2794 0 9.89782 0 9.5Z"
        fill="#1B212C"
      />
    </svg>
  );
};
